/* 차트 페이지 레이아웃 */
.chartCen {
  max-width: 1100px;
}

/* 차트 조회 스타일 */
.chartArea {
  display: flex;
  width: 1000px;
  margin-bottom: 20px;
}

.chartSelect {
  width: 1000px;
  display: flex;
}

.chartComment {
  width: 798px;
  margin-top: 8px;
  margin-bottom: 15px;
  font-size: 15px;
}

.chartSelectorStyle {
  width: 200px;
  text-align: center;
  border: 1px solid #fff;
  height: 30px;
  margin-right: 10px;
  border-radius: 4px;
  float: right;
}

.chart1Area {
  /* display: flex; */
  width: 400px;
}

.chart2Area {
  /* display: flex; */
  width: 400px;
}

.chartName {
  text-align: center;
  background: #ff0000;
  color: #fff;
  padding: 10px;
  font-size: 15px;
}

.chartName2 {
  text-align: center;
  background: #0000ff;
  color: #fff;
  padding: 10px;
  font-size: 15px;
}

.getChartBtn {
  background-color: #0074bd;
  border: 1px solid #0074bd;
  color: #fff;
  cursor: pointer;
  width: 100px;
  height: 70px;
  font-size: 16px;
}

.getChartBtn:hover {
  font-weight: bold;
  border: 1px solid #0074bd;
}

.chart1Selector {
  display: flex;
}

.graphLayout {
  /* border: 1px solid #000; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 300px;
  background-color: #e4e4e4;
  /* text-align: center; */
  padding-top: 2px;
}

/* 이미지 다운로드 버튼 스타일 */
.downImgBtn {
  border: none;
  border-radius: 10px;
  width: 110px;
  height: 26px;
  cursor: pointer;
  float: right;
  margin-bottom: 20px;
}

.imgDown {
  height: 26px;
  width: 110px;
}

/* 차트 스타일 */
.chartLayout {
  width: 900px;
  height: 500px;
}

.chartStyle {
  width: 900px;
  background-color: #fff;
}
