@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #333;
}

li,
ol {
  list-style: none;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  margin: auto;
}

code {
  font-family: 'Noto Sans KR', sans-serif;
}

body {
  background-color: #c8c8c8;
}

.pageMargin {
  margin-top: 30px;
}
