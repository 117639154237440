/* dataList layout */
.dataCen {
  max-width: 1600px;
}

.dataSelect {
  max-width: 800px;
  display: flex;
}

/* 테이블 버튼 */

.getTableBtn {
  background-color: #0074bd;
  border: 1px solid #0074bd;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  width: 80px;
  height: 46px;
  font-size: 16px;
}

.getTableBtn:hover {
  font-weight: bold;
  border: 1px solid #0074bd;
}

/* 셀렉트 css */
.selectorStyle {
  width: 120px;
  text-align: center;
  border: 1px solid #fff;
  height: 46px;
  margin-right: 10px;
  border-radius: 4px;
}

/* 멘트 css */
.dataListComment {
  max-width: 800px;
  margin-top: 10px;
  font-size: 15px;
}

.max30Comment {
  color: #ff0000;
}

/* 달력 CSS */
.datePicker {
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  height: 46px;
  color: #000;
  text-align: center;
  border: 1px solid #fff;
  font-size: 16px;
  /* padding-right: 14px; */
  margin-right: 10px;
}

.datePicker:focus {
  border: 2px solid #000;
}

/* 테이블 */
/* tableLayout Css */
.tableCen {
  max-width: 1000px;
  margin-top: 20px;
}

.downloadComment {
  font-size: 14px;
  display: inline;
  float: left;
}

/* 엑셀 다운로드 버튼 css */
.downExcelBtn {
  width: 100px;
  text-align: right;
  border: none;
  border-radius: 4px;
  float: right;
  cursor: pointer;
}

.exceldown {
  width: 100px;
  display: block;
}

/* 테이블 styling */
.tableDataCen {
  width: 1000px;
  /* text-align: center; */
}

.tableStyle {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
  margin-top: 60px;
  margin-bottom: 60px;
}

.tableHead th,
.tableBody td {
  width: calc(100% / 10);
  padding: 8px;
  text-align: center;
}

.tableHead th {
  /* background-color: #e2efda; */
  background-color: #000;
  color: #fff;
  border: 1px solid #d4d4d4;
  font-size: 16px;
  font-weight: 400;
}

.tableBody td {
  border: 1px solid #d4d4d4;
  background-color: #fff;
  font-size: 14px;
}
