/* layout */
.layout {
  /* margin: 0 auto; */
}

.header {
  background-color: #353535;
  margin: 0 auto;
}

.nav {
  max-width: 1800px;
  height: 100px;
}

/* header */
/* 왼쪽 헤더 : navLogo */

.navLogo {
  /* width: 100%; */
  height: 100%;
  display: inline-block;
}

.logo {
  height: 100%;
}

.logoImg {
  margin-top: 11px;
}

/* 오른쪽 헤더: navMenu */

.navMenu {
  float: right;
  color: #fff;
  height: 100px;
}

.curTimeStamp {
  /* 타임스탬프 */
  color: #fff;
  text-align: right;
  margin-top: 8px;
}

.navList {
  display: flex;
  margin-top: 10px;
}

.menuName {
  color: #353535;
  font-size: 18px;
  font-weight: 400;
  background-color: #c8c8c8;
  margin-left: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid none;
}

.menuName:hover {
  color: white;
  background-color: black;
}

.active {
  color: white;
  background-color: black;
}
