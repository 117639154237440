.cen {
  max-width: 1800px;
}

.solarMain {
  display: flex;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
}

.areaTable {
  /* border: 1px solid #000; */
  width: 890px;
  height: 273px;
  background-color: white;
  margin-bottom: 10px;
}

.area1H {
  padding-right: 10px;
}

/* 테이블 정보 스타일링 */
.mTableBody td {
  border: 1px solid #d4d4d4;
  background-color: #fff;
  text-align: center;
  font-size: 14px;
}

.tableInfo {
  background-color: #353535;
  color: #fff;
  text-align: center;
  line-height: 46px;
  height: 46px;
  position: relative;
  font-size: 20px;
}

.tableUpdate {
  right: 10px;
  top: 6px;
  position: absolute;
  font-size: 12px;
}

/* 테이블 스타일링 */
.mTable {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #000; */
  table-layout: fixed;
}

.mTable th,
.mTable td {
  width: calc(100% / 10);
  padding: 8px;
  text-align: center;
}

.mTableHead th {
  /* background-color: #e2efda; */
  background-color: #000;
  color: #fff;
  border: 1px solid #d4d4d4;
  text-align: center;
  font-weight: bold;
  font-weight: 400;
}
